import React, {Component} from "react"
import styles from "./SignUp.module.css"
import {LoginWrapper} from "components/LoginWrapper"
import {Button} from "pages/sign-in/parts/Button"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {KlassDropdown} from "components/inputs/KlassDropdown"
import {Captcha} from "components/Captcha"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {Authorisation} from "models/Authorisation"
import {Link} from "react-router-dom"
import {findErrors} from "../../lib/functions/findErrors"
import {Model} from "Model"
import {InputMaskNumber} from "components/InputMaskNumber/InputMaskNumber"

interface Props {
    auth: Authorisation
    model: Model
}

export interface SignUpUser {
    first_name?
    last_name?
    email?
    password?
    confirm_password?
    json_data: {
        preferred_contact_time?: "morning" | "noon" | "evening"
        phone_number?: string
        how_attend?: string
        previously_applied?: "Yes" | "No"
        crm_id?
        rep_code?
        lead_source?
        about_us: string
        specify?: string
    }
    captcha_value?: string
}

const options1 = [
    {label: "Morning", value: "morning"},
    {label: "Noon", value: "noon"},
    {label: "Evening", value: "evening"}
]

const options2 = [
    {label: "Online", value: "online"},
    {label: "Learning Site", value: "Learning Site"}
]

const options3 = [
    {label: "Yes", value: "Yes"},
    {label: "No", value: "No"}
]

const hearAboutUs = [
    {value: "youtube", label: "Youtube"},
    {value: "google", label: "Google"},
    {value: "facebook", label: "Facebook"},
    {value: "twitter", label: "Twitter"},
    {value: "instagram", label: "Instagram"},
    {value: "email", label: "Email"},
    {value: "tv", label: "TV"},
    {value: "newspaper", label: "Newspaper"},
    {value: "other", label: "Other"}
]

const pleaseSpecify = [
    {value: "School Website", label: "School Website"},
    {value: "Facebook", label: "Facebook"},
    {value: "Blog", label: "Blog"},
    {value: "Instagram", label: "Instagram"},
    {value: "Pinterest", label: "Pinterest"},
    {value: "Twitter", label: "Twitter"},
    {value: "Google", label: "Google"},
    {value: "Other Search Engine", label: "Other Search Engine"},
    {value: "Salon Referral", label: "Salon Referral"},
    {value: "Student Referral", label: "Student Referral"},
    {value: "Alumni Referral", label: "Alumni Referral"},
    {value: "Instructor Referral", label: "Instructor Referral"},
    {value: "Signage", label: "Signage"},
    {value: "Word of Mouth", label: "Word of Mouth"},
    {value: "AdWords Campaign", label: "AdWords Campaign"},
    {value: "Apply Now Form", label: "Apply Now Form"},
    {value: "Book a Tour Form", label: "Book a Tour Form"},
    {value: "Career Fair", label: "Career Fair"},
    {value: "College Career Fairs", label: "College Career Fairs"},
    {value: "Contact Us/Request Info Form", label: "Contact Us/Request Info Form"},
    {value: "Eblast", label: "Eblast"},
    {value: "Net Price Calculator", label: "Net Price Calculator"},
    {value: "Scholarship Form", label: "Scholarship Form"},
    {value: "Stay Informed", label: "Stay Informed"},
    {value: "Sticky form", label: "Sticky form"},
    {value: "Tell Me more form", label: "Tell Me more form"},
    {value: "Event (hosted at your school)", label: "Event (hosted at your school)"},
    {value: "FAFSA/ISIR", label: "FAFSA/ISIR"},
    {value: "FMS Hot Bot", label: "FMS Hot Bot"},
    {value: "Google Review", label: "Google Review"},
    {value: "Linked-in", label: "Linked-in"},
    {value: "Military Base Visits", label: "Military Base Visits"},
    {value: "Networking", label: "Networking"},
    {value: "Open House", label: "Open House"},
    {value: "Personally Developed Inquiry (PDI)", label: "Personally Developed Inquiry (PDI)"},
    {value: "Post Card", label: "Post Card"},
    {value: "Print Ad", label: "Print Ad"},
    {value: "Newspaper", label: "Newspaper"},
    {value: "Radio", label: "Radio"},
    {value: "Television", label: "Television"},
    {value: "Thryv", label: "Thryv"},
    {value: "Vocational Rehab", label: "Vocational Rehab"},
    {value: "YouTube", label: "YouTube"},
    {value: "Walk In", label: "Walk In"},
    {value: "Classroom Presentations", label: "Classroom Presentations"},
    {value: "Guest Referral", label: "Guest Referral"},
    {value: "Oozle Media", label: "Oozle Media"},
    {value: "Beauty Schools Directory", label: "Beauty Schools Directory"},
    {value: "GE Google AdWords", label: "GE Google AdWords"},
    {value: "Bedord Career Fair", label: "Bedord Career Fair"},
    {value: "High School Visit", label: "High School Visit"},
    {value: "Public Event", label: "Public Event"},
    {value: "Red Lemon Digital campaign", label: "Red Lemon Digital campaign"}
]

const mandatory = {
    presence: {allowEmpty: false}
}

const rules = {
    first_name: {...mandatory, length: {minimum: 3}},
    last_name: {...mandatory, length: {minimum: 3}},
    email: {
        email: true
    },
    password: {
        ...mandatory,
        length: {minimum: 3}
    },
    confirm_password: {
        ...mandatory,
        equality: "password"
    },
    captcha_value: mandatory
}

const nestedRules = {
    preferred_contact_time: mandatory,
    phone_number: mandatory,
    specify: mandatory,
    previously_applied: mandatory,
    about_us: mandatory
}

@observer
export class SignUp extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private nestedErrors: {[key: string]: string} = {}

    @observable
    private backendErrors: string | null = ""

    @observable
    private user: SignUpUser = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        json_data: {phone_number: "", crm_id: "", rep_code: "", about_us: "", specify: ""},
        captcha_value: ""
    }

    private OTHER_CONSTANT = {
        keyOther: "about_us"
        // valueOther: "Other"
    }

    private recaptchaRef: any = React.createRef()

    private onChangeCaptchaText = (id: string, value: string) => {
        this.user[id] = value
    }

    private isValid = () => {
        const {isValid, errors} = findErrors(this.user, rules)
        const {isValid: nestedIsValid, errors: nestedErrors} = findErrors(
            this.user.json_data,
            nestedRules
        )
        this.errors = errors
        this.nestedErrors = nestedErrors
        return isValid && nestedIsValid
    }

    public render() {
        const user = this.user
        const {onChangeCaptchaText, backendErrors} = this
        // const {valueOther} = this.OTHER_CONSTANT
        return (
            <LoginWrapper className={styles.wrapper}>
                <div className={styles.root}>
                    <header className={styles.header}>
                        <h2 className={styles.h2}>Create a New Account</h2>
                        <Link to="/sign-in" className={styles.link}>
                            Sign In
                        </Link>
                    </header>
                    <p className={styles.text}>
                        Thank you for your interest in applying to <br /> The Salon Professional
                        Academy.
                    </p>
                    <br />
                    <p className={styles.text}>
                        Please complete the following information and use the Register button to
                        begin the application process.
                    </p>
                    <div className={styles.formArea}>
                        <Label
                            text="Email Address"
                            className={styles.inputUser}
                            error={this.errors["email"]}
                            required>
                            <Input
                                onChange={(v) => {
                                    user.email = v
                                }}
                                value={user.email}
                                /* {
                                    ...(user.email).includes("yopmail") ?
                                    (delete rules["captcha_value"]):(rules["captcha_value"])} */
                                type="email"
                            />
                        </Label>

                        <Label
                            text="Password"
                            required
                            className={styles.inputPass}
                            error={this.errors["password"]}>
                            <Input
                                onChange={(v) => {
                                    user.password = v
                                }}
                                value={user.password}
                                type="password"
                            />
                        </Label>

                        <Label
                            text="Confirm Password"
                            required
                            className={styles.inputPass}
                            error={this.errors["confirm_password"]}>
                            <Input
                                onChange={(v) => {
                                    user.confirm_password = v
                                }}
                                value={user.confirm_password}
                                type="password"
                            />
                        </Label>

                        <Label
                            text="First Name"
                            required
                            className={styles.inputPass}
                            error={this.errors["first_name"]}>
                            <Input
                                onChange={(v) => {
                                    user.first_name = v
                                }}
                                value={user.first_name}
                            />
                        </Label>

                        <Label
                            text="Last Name"
                            required
                            className={styles.inputPass}
                            error={this.errors["last_name"]}>
                            <Input
                                onChange={(v) => {
                                    user.last_name = v
                                }}
                                value={user.last_name}
                            />
                        </Label>

                        <Label
                            text="Preferred Contact Time"
                            required
                            error={this.nestedErrors["preferred_contact_time"]}>
                            <KlassDropdown
                                onChange={(v) => {
                                    user.json_data.preferred_contact_time = v.value
                                }}
                                options={options1}
                                value={options1.find((el) => {
                                    return el.value === user.json_data.preferred_contact_time
                                })}
                            />
                        </Label>

                        <Label
                            text="Phone Number"
                            required
                            className={styles.inputPass}
                            error={this.nestedErrors["phone_number"]}>
                            <InputMaskNumber
                                onChange={(v) => {
                                    user.json_data.phone_number = v
                                }}
                                value={user.json_data.phone_number}
                                type="number"
                                mask="999-999-9999"
                            />
                        </Label>

                        {/* <Label
                            text="How would you like to attend?"
                            required
                            error={this.nestedErrors["how_attend"]}>
                            <KlassDropdown
                                onChange={(v) => {
                                    user.json_data.how_attend = v.value
                                }}
                                options={options2}
                                value={options2.find((el) => {
                                    return el.value === user.json_data.how_attend
                                })}
                            />
                        </Label> */}

                        <Label
                            text="How did you hear about us?"
                            required
                            error={this.nestedErrors["about_us"]}>
                            <KlassDropdown
                                onChange={(v) => {
                                    user.json_data.about_us = v.value
                                }}
                                options={hearAboutUs}
                                value={hearAboutUs.find((el) => {
                                    return el.value === user.json_data.about_us
                                })}
                            />
                        </Label>

                        <Label text="Please specify" required error={this.nestedErrors["specify"]}>
                            <KlassDropdown
                                onChange={(v) => {
                                    user.json_data.specify = v.value
                                }}
                                options={pleaseSpecify}
                                value={pleaseSpecify.find((el) => {
                                    return el.value === user.json_data.specify
                                })}
                            />
                        </Label>
                        {/* {user.json_data.about_us === valueOther && (
                            <Label
                                text="Describe how did you hear about us / Referal name"
                                className={styles.inputPass}>
                                <Input
                                    onChange={(v) => {
                                        user.json_data.about_us_text = v
                                    }}
                                    value={user.json_data.about_us_text}
                                />
                            </Label>
                        )} */}

                        <Label
                            text="Have you previously applied to The Salon Professional Academy?"
                            required
                            error={this.nestedErrors["previously_applied"]}>
                            <KlassDropdown
                                onChange={(v) => {
                                    user.json_data.previously_applied = v.value
                                }}
                                options={options3}
                                value={options3.find((el) => {
                                    return el.value === user.json_data.previously_applied
                                })}
                            />
                        </Label>
                        <Captcha
                            recaptchaRef={this.recaptchaRef}
                            id={`captcha_value`}
                            user={this.user}
                            onChange={onChangeCaptchaText}
                            errors={this.errors}
                            backendErrors={backendErrors}
                        />
                        {/* {
                            (user.email).includes("yopmail") ?
                            (<label></label>):(<Captcha
                           recaptchaRef={this.recaptchaRef}
                           id={`captcha_value`}
                           user={this.user}
                           onChange={onChangeCaptchaText}
                           errors={this.errors}
                           backendErrors={backendErrors}
                        />)} */}
                    </div>
                    <p className={styles.textUnderForm}>
                        Please make a note of your email address and password as they will be
                        required should you wish to make application changes in the future.
                    </p>

                    <Button
                        text="SIGN UP"
                        onClick={this.signUp.bind(this)}
                        className={styles.button}
                    />
                </div>
            </LoginWrapper>
        )
    }

    private async signUp() {
        this.backendErrors = ""
        const CAPTCHA_WORD = "Captcha"
        if (this.isValid()) {
            this.user.json_data.lead_source = localStorage.getItem("lead_source")
            const response = await this.props.auth.signUp("tspa", {...this.user})
            if (this.recaptchaRef.current) {
                this.recaptchaRef.current.reset()
                this.user.captcha_value = ""
            }
            if (!response.success) {
                const {
                    errors: [{message}]
                } = response
                if (message.includes(CAPTCHA_WORD)) {
                    this.backendErrors = message
                }
            }
        }
    }
}
